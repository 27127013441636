import React, { useContext } from "react";
import { LocalizationApi } from "../../../context/localizationContext";
import { ThemeApi } from "../../../context/themeContext";

const Skills = () => {
  const { theme } = useContext(ThemeApi);
  const { language } = useContext(LocalizationApi);

  return (
    <div>
      <div
        className={`rounded bg-dark1 rounded-t-none border-2 py-4 text-center   ${
          theme === "light"
            ? "bg-light1  border-light1"
            : "bg-dark1 border-light2"
        }`}>
        <h5 className="text-blue font-bold">
          {" "}
          {language === "uz"
            ? "KO'NIKMALAR"
            : language === "ru"
            ? "НАВЫКИ"
            : "SKILLS"}
        </h5>
      </div>
      <div className="max-w-max m-5 flex flex-col sm:flex-row items-start gap-8 xl:gap-40 xl:pt-5 sm:px-5 xl:px-20">
        <div>
          <h4 className="text-blue text-2xl font-semibold">Frontend</h4>
          <ul className="list-disc marker:text-blue mx-6 mt-3 flex flex-col items-start gap-3">
            <li>HTML, CSS, SCSS, Tailwind CSS</li>
            <li>Javascript, Typescript</li>
            <li>React JS, React Native, Next JS</li>
            <li>Material Tailwind, Magic UI</li>
            <li>Zustand, Redux</li>
            <li>BEM, DRY, SEO</li>
          </ul>
        </div>
        <div className="border-t sm:border-none border-blue pt-8 sm:pt-0">
          <h4 className="text-blue text-2xl font-semibold">Backend</h4>
          <ul className="list-disc marker:text-blue mx-6 mt-3 flex flex-col items-start gap-3">
            <li>Node JS, Express JS, Nest JS</li>
            <li>Telegram Bot, grammY, Telegraf JS</li>
            <li>MongoDB, PostgreSQL, Redis</li>
            <li>Mongoose, Sequalize, Knex</li>
            <li>Socket.IO, EJS, Swagger</li>
            <li>Git, Github, Docker</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Skills;
