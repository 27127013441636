import React, { useContext } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { LocalizationApi } from "../../../context/localizationContext";
import { ThemeApi } from "../../../context/themeContext";

const Experience = () => {
  const { theme } = useContext(ThemeApi);
  const { language } = useContext(LocalizationApi);

  return (
    <div>
      <div
        className={`rounded bg-dark1 rounded-t-none border-2 py-4 text-center   ${
          theme === "light"
            ? "bg-light1  border-light1"
            : "bg-dark1 border-light2"
        }`}>
        <h5 className="text-blue font-bold">
          {" "}
          {language === "uz"
            ? "TAJRIBA"
            : language === "ru"
            ? "ОПЫТ"
            : "EXPERIENCE"}
        </h5>
      </div>
      <div className="mx-5 mt-5 flex flex-col gap-3">
        <div className="flex flex-row items-center gap-2">
          <img
            className="rounded-full w-8"
            src="https://marketing.uz/uz/uploads/works/covers/c6c1569b46e710f6ffefdfb5d8f046d7.jpg"
            alt="logo"
          />
          <h6 className="font-bold text-2xl">Najot Ta'lim</h6>
        </div>
        <div className="flex flex-row items-start">
          <div className="flex flex-col items-start gap-5">
            <div className="flex flex-col items-start gap-2">
              <p className="ml-5 font-thin uppercase">
                {language === "uz"
                  ? "BACK-END WEB DASTURLASH - NODE.JS MENTOR"
                  : language === "ru"
                  ? "NODE.JS МЕНТОР - BACK-END WEB РАЗРАБОТКА"
                  : "BACK-END WEB DEVELOPMENT - NODE.JS MENTOR"}
              </p>
              <span
                className={`ml-5 flex flex-row items-center gap-2 font-thin text-xs sm:text-sm opacity-60 ${
                  theme === "light" ? "text-dark2" : "text-light2"
                }`}>
                <FaCalendarAlt />
                2024
                {language === "uz"
                  ? " Dekabr - Hozirda ishlamoqdaman"
                  : language === "ru"
                  ? " Декабрь - сейчас работаю"
                  : " December - Currently working"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-5 mt-5 flex flex-col gap-3">
        <div className="flex flex-row items-center gap-2">
          <img
            className="rounded-full w-8"
            src="https://ik.imagekit.io/ekbcszw7tx/Portfolio/CoddyCamp_logo.png"
            alt="logo"
          />
          <h6 className="font-bold text-2xl">CoddyCamp</h6>
        </div>
        <div className="flex flex-row items-start">
          <div className="flex flex-col items-center gap-1 text-center mt-2 ml-3">
            <span className="w-2 h-2 bg-blue rounded-full opacity-50"></span>
            <span className="w-[2px] h-[75px] sm:h-14 bg-blue rounded opacity-50"></span>
            <span className="w-2 h-2 bg-blue rounded-full opacity-50"></span>
          </div>
          <div className="flex flex-col items-start gap-5">
            <div className="flex flex-col items-start gap-2">
              <p className="ml-5 font-thin uppercase">
                {language === "uz"
                  ? "LMS platformasining bosh frontend dasturchisi"
                  : language === "ru"
                  ? "Lead Frontend Developer в LMS системе"
                  : "Lead Frontend Developer for LMS"}
              </p>
              <span
                className={`ml-5 flex flex-row items-center gap-2 font-thin text-xs sm:text-sm opacity-60 ${
                  theme === "light" ? "text-dark2" : "text-light2"
                }`}>
                <FaCalendarAlt />
                2024
                {language === "uz"
                  ? " Iyun - 2025 Yanvar"
                  : language === "ru"
                  ? " Июнь - 2025 Январь"
                  : " June - 2025 January"}
              </span>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="ml-5 font-thin uppercase">
                {language === "uz"
                  ? "FULL-STACK WEB DASTURLASH - MENTOR"
                  : language === "ru"
                  ? "Ментор - FULL-STACK WEB РАЗРАБОТКА"
                  : "FULL-STACK WEB DEVELOPMENT - MENTOR"}
              </p>
              <span
                className={`ml-5 flex flex-row items-center gap-2 font-thin text-xs sm:text-sm opacity-60 ${
                  theme === "light" ? "text-dark2" : "text-light2"
                }`}>
                <FaCalendarAlt />
                2023
                {language === "uz"
                  ? " Iyul - 2024 Dekabr"
                  : language === "ru"
                  ? " Июль - 2024 Декабрь"
                  : " July - 2024 December"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="ml-5 w-40 h-1 my-5 bg-blue border-0 rounded"></hr> */}
      <div className="mx-5 my-8 flex flex-col gap-3">
        <div className="flex flex-row items-center gap-2">
          <img
            className="rounded-full w-8"
            src="https://ik.imagekit.io/ekbcszw7tx/Portfolio/mars-logo"
            alt="logo"
          />
          <h6 className="font-bold text-2xl">Mars IT School</h6>
        </div>
        <div className="flex flex-col items-start gap-2">
          <p className="font-thin uppercase">
            {language === "uz"
              ? "FRONTEND WEB DASTURLASH - INSTRUKTOR"
              : language === "ru"
              ? "Инструктор - Frontend WEB Разработка"
              : "FRONTEND WEB DEVELOPMENT - INSTRUCTOR"}
          </p>
          <span
            className={`flex flex-row items-center gap-2 font-thin text-xs sm:text-sm opacity-60 ${
              theme === "light" ? "text-dark2" : "text-light2"
            }`}>
            <FaCalendarAlt />
            2023{" "}
            {language === "uz"
              ? "Iyun - 2023 Iyul"
              : language === "ru"
              ? "Июнь - 2023 Июль"
              : "June - 2023 July"}
          </span>
        </div>
        {/* <hr className="w-40 h-1 my-3 bg-blue border-0 rounded"></hr> */}
      </div>
    </div>
  );
};

export default Experience;
